import React, { ChangeEvent, FormEvent, useState } from 'react';

interface User {
    email: string;
    firstName: string;
    lastName: string;
    companyName: string;
    password: string;
}

interface UserFormProps {
    fetchUsers: () => void;
}

const AccountCreate: React.FC<UserFormProps> = ({ fetchUsers }) => {
    const [user, setUser] = useState<User>({
        email: '',
        firstName: '',
        lastName: '',
        companyName: '',
        password: '',
    });
    const [successMessage, setSuccessMessage] = useState<string>('');

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const response = await fetch('http://localhost:3001/accounts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(user),
            });
            if (response.ok) {
                fetchUsers();
                setSuccessMessage(`User ${user.email} account successfully created`);
            } else {
                console.error('Error adding user:', response.statusText);
            }
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input type="email" name="email" placeholder="Email" onChange={handleChange} required />
                <input type="text" name="firstName" placeholder="First Name" onChange={handleChange} required />
                <input type="text" name="lastName" placeholder="Last Name" onChange={handleChange} required />
                <input type="text" name="companyName" placeholder="Company Name" onChange={handleChange} required />
                <input type="password" name="password" placeholder="Password" onChange={handleChange} required />
                <button type="submit">Add Account</button>
            </form>
            {successMessage && <p>{successMessage}</p>}
        </div>
    );
};

export default AccountCreate;