import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface LoginProps {
    onLogin: (token: string) => void;
}

interface LoginResponse {
    token: string;
    isCGENAdmin: boolean;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            console.log('Starting fetch request...');
            console.log('Request URL:', 'https://3ff00wuy2j.execute-api.us-east-1.amazonaws.com/prod/admin/login');
            console.log('Request Headers:', {
                'Content-Type': 'application/json',
                'x-api-key': 'testingkey',
            });
            console.log('Request Body:', { email, password });

            const response = await fetch('https://3ff00wuy2j.execute-api.us-east-1.amazonaws.com/prod/admin/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'rl9LN6I45X3tU3lqg8pVS5lH8LrnHmvO15Uklcpw',
                },
                body: JSON.stringify({ email, password }),
            });

            console.log('Fetch request completed. Checking response status...');
            console.log('Response Status:', response.status);
            console.log('Response Headers:', response.headers);

            if (response.ok) {
                console.log('Response is OK. Parsing JSON...');
                const data: LoginResponse = await response.json();
                console.log('JSON parsed successfully:', data);
                onLogin(data.token);
                if (data.isCGENAdmin) {
                    console.log('User is CGEN Admin. Navigating to /admin...');
                    navigate('/admin');
                } else {
                    console.log('User is not CGEN Admin. Navigating to /customer...');
                    navigate('/customer');
                }
            } else {
                console.error('Login failed. Response status:', response.status);
                const errorText = await response.text();
                console.error('Response Body:', errorText);
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            <button type="submit">Login</button>
        </form>
    );
};

export default Login;