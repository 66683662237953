import React, { useEffect, useState } from 'react';
import './AccountList.css';

interface User {
    email: string;
    firstName: string;
    lastName: string;
    companyName: string;
}

const AccountList: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch('http://localhost:3001/company/account_list');
                const data: User[] = await response.json();
                console.log('Fetched users:', data); // Log the fetched data
                setUsers(data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    return (
        <div className="table-container">
            <h2>Company Accounts</h2>
            <table className="table">
                <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Company Name</th>
                </tr>
                </thead>
                <tbody>
                {users.length > 0 ? (
                    users.map((user, index) => (
                        <tr key={index}>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.email}</td>
                            <td>{user.companyName}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={4}>No users found</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default AccountList;