import React from 'react';
import {Link} from 'react-router-dom';
import AccountCreate from './AccountCreate';

const CGenAdmin: React.FC = () => {

    const fetchUsers = () => {
        // Implement the logic to fetch users
    };

    return (
        <div>
            <h2>C-Gen Customer Accounts</h2>
            <AccountCreate fetchUsers={fetchUsers}/>
            <div>
                <Link to="/user_list">Account List</Link>
            </div>
        </div>
    );
};

export default CGenAdmin;