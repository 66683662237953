import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import Login from './Login';
import AccountCreate from './AccountCreate';
import CGenAdmin from './CGenAdmin';
import './App.css';
import AccountList from "./AccountList";
import CustomerUserCreate from './CustomerUserCreate';
import CustomerUserList from './CustomerUserList';

const App: React.FC = () => {
    const [token, setToken] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (typeof document !== 'undefined') {
            const savedToken = Cookies.get('token');
            if (savedToken) {
                setToken(savedToken);
            }
            setLoading(false);
        }
    }, []);

    const handleLogin = (token: string) => {
        if (typeof document !== 'undefined') {
            Cookies.set('token', token, { expires: 1 }); // Expires in 1 day
            setToken(token);
        }
    };

    const handleLogout = () => {
        if (typeof document !== 'undefined') {
            Cookies.remove('token');
            setToken(null);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <div className="App">
                <h1>C-Gen AI</h1>
                {token && <button onClick={handleLogout} style={{ position: 'absolute', top: 10, right: 10 }}>Logout</button>}
                <LocationBasedTitle/>
                <Routes>
                    <Route path="/login" element={<Login onLogin={handleLogin}/>}/>
                    <Route path="/admin" element={token ? <AdminPage /> : <Navigate to="/login"/>}/>
                    <Route path="/company-admin" element={token ? <CGenAdmin/> : <Navigate to="/login"/>}/>
                    <Route path="/user_list" element={token ? <AccountList/> : <Navigate to="/login"/>}/>
                    <Route path="/customer" element={token ? <CustomerUserCreate/> : <Navigate to="/login"/>}/>
                    <Route path="/customer/user_list" element={token ? <CustomerUserList/> : <Navigate to="/login"/>}/>
                    <Route path="*" element={token ? <Navigate to="/admin"/> : <Navigate to="/login"/>}/>
                </Routes>
                {token && <ConditionalUserList/>}
            </div>
        </Router>
    );
};

const LocationBasedTitle: React.FC = () => {
    const location = useLocation();
    let title = 'Create Account:';
    if (location.pathname === '/customer') {
        title = 'Create User:';
    } else if (location.pathname === '/login') {
        title = 'Login:';
    }
    return <h3>{title}</h3>;
};

const ConditionalUserList: React.FC = () => {
    const location = useLocation();
    if (location.pathname === '/admin') {
        return <AccountList/>;
    } else if (location.pathname === '/customer') {
        return <CustomerUserList/>;
    }
    return null;
};

const AdminPage: React.FC = () => {
    return (
        <div>
            <p>You are logged in as a C-Gen admin</p>
            <AccountCreate fetchUsers={() => {}} />
        </div>
    );
};

export default App;